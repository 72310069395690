import { ReactElement } from 'react';
import { formatPhoneNumber } from 'lib/util';
import { IPageLocationFields } from 'types/contentful';
import ScheduleAppointmentCta from 'components/UI/ScheduleAppointmentCta';

export default function AddressBlock(officeDetails: IPageLocationFields): ReactElement {
  return (
    <div className="leading-tight text-base lg:text-lg my-2">
      <address className="not-italic">
        {officeDetails.address1} <br />
        {officeDetails.address2 && (
          <>
            {officeDetails.address2}
            <br />
          </>
        )}
        {officeDetails.address3 && (
          <>
            {officeDetails.address3}
            <br />
          </>
        )}
        {officeDetails.city + ', ' + officeDetails.state + ' ' + officeDetails.zipCode}
        <br />
      </address>

      <a className="block text-tertiary-actual underline" href={`tel:${officeDetails.phoneNumber}`}>
        {formatPhoneNumber(officeDetails.phoneNumber)}
      </a>

      <ScheduleAppointmentCta
        location={officeDetails}
        colorScheme="Primary"
        classNames="mt-6 hidden md:inline-block"
      />
    </div>
  );
}
