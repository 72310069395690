import { Asset } from 'contentful';
import { ReactElement, useState } from 'react';
import { IFragmentInsuranceFields } from 'types/contentful';
import PhotoGalleryBlock from './PhotoGalleryBlock';
import CTA from 'components/UI/Cta';

interface InsuranceProvidersProps {
  insuranceProviders: IFragmentInsuranceFields[];
  insuranceDisclaimer?: string;
  insuranceSlug?: string;
}

export default function InsuranceProviders({
  insuranceProviders,
  insuranceDisclaimer,
  insuranceSlug,
}: InsuranceProvidersProps): ReactElement {
  const [showInsurance, setShowInsurance] = useState(true);
  return (
    <div className="mt-6 p-4 lg:px-16">
      <h2 className="font-heading text-xl">Accepted Insurances</h2>
      <div className="flex flex-wrap my-2">
        {insuranceProviders
          ?.filter((item) => item.image !== undefined)
          .map((item, i) => (
            <PhotoGalleryBlock key={i} image={item.image as Asset} query="w=450" width={5} />
          ))}
      </div>

      {/* See Less/ See More */}
      <div className="my-2">
        {showInsurance ? (
          <a
            className="text-secondary-actual"
            onClick={() => setShowInsurance(false)}
            onKeyPress={() => setShowInsurance(false)}
            role="button"
            tabIndex={0}
          >
            See Less
          </a>
        ) : (
          <a
            className="text-secondary-actual"
            onClick={() => setShowInsurance(true)}
            onKeyPress={() => setShowInsurance(true)}
            role="button"
            tabIndex={0}
          >
            See More
          </a>
        )}
      </div>

      {/* Bulleted List */}
      {showInsurance ? (
        <ul className="list-disc ml-4 text-base">
          {insuranceProviders.map((insurance, i) => (
            <li key={i}>{insurance.insurer}</li>
          ))}
        </ul>
      ) : (
        ''
      )}

      {insuranceSlug && (
        <div className="my-8">
          <CTA text="Insurance & Payment" colorScheme="Primary3" slug={insuranceSlug} />
        </div>
      )}

      {insuranceDisclaimer && (
        <div className="mt-3 bg-neutral text-sm rounded-md p-3 w-full md:flex md:justify-center md:text-center hidden md:mt-10 md:mb-8">
          {insuranceDisclaimer}
        </div>
      )}
    </div>
  );
}
