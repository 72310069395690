import { JsonObject } from 'lib/json';
import { Review } from 'lib/reviewinc';
import { removeIfUndefined } from 'lib/util';

export type RatingType = 'doctor' | 'location' | 'practice';

const schemaTypes: Record<RatingType, string> = {
  doctor: 'Physician',
  location: 'MedicalClinic',
  practice: 'Organization',
};

export function reviewSchema({
  type,
  id,
  outOfFive,
  count,
  reviews,
}: {
  type: RatingType;
  id?: string;
  outOfFive?: number;
  count?: number;
  reviews?: Review[];
}): JsonObject {
  return {
    '@type': schemaTypes[type],
    ...removeIfUndefined({ '@id': id }),
    ...(outOfFive || count
      ? {
          aggregateRating: {
            '@type': 'AggregateRating',
            ...removeIfUndefined({
              ratingValue: outOfFive,
              reviewCount: count,
            }),
          },
        }
      : {}),
    ...(reviews
      ? {
          reviews: reviews.map((review) => ({
            '@type': 'Review',
            author: review.name,
            reviewBody: review.comment,
            reviewRating: {
              '@type': 'Rating',
              ratingValue: review.fiveStar,
            },
          })),
        }
      : {}),
  };
}
